import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

class BackgroundSection extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(quality: 90, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <BackgroundImage
              fluid={
                data.allImageSharp.edges.find(element => {
                  // Match string after final slash
                  return (
                    element.node.fluid.src.split("/").pop() ===
                    this.props.imgsrc
                  )
                }).node.fluid
              }
              className={this.props.className}
              //   Tag="section"
            >
              {this.props.children}
            </BackgroundImage>
          )
        }}
      />
    )
  }
}

const StyledBackgroundSection = styled(BackgroundSection)`
  height: 100%;
  width: 100%;
`

export default StyledBackgroundSection

// const BackgroundSection = ({ className }) => {
//   const data = useStaticQuery(
//     graphql`
//       query {
//         desktop: file(relativePath: { eq: "seamless-bg-desktop.jpg" }) {
//           childImageSharp {
//             fluid(quality: 90, maxWidth: 1920) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     `
//   )

//   // Set ImageData.
//   const imageData = data.desktop.childImageSharp.fluid

//   return (
//     <BackgroundImage
//       Tag="section"
//       className={className}
//       fluid={imageData}
//       backgroundColor={`#040e18`}
//     >
//       <h2>gatsby-background-image</h2>
//     </BackgroundImage>
//   )
// }

// const StyledBackgroundSection = styled(BackgroundSection)`
//   width: 100%;
//   background-position: bottom center;
//   background-repeat: repeat-y;
//   background-size: cover;
// `

// export default StyledBackgroundSection
